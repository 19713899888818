import Steps from 'components/Commerce/Steps';
import QuantityStep from 'components/Commerce/Steps/QuantityStep';
import AddToCartStep from 'components/Commerce/Steps/AddToCartStep';
import VariationStep from 'components/Commerce/Steps/VariationStep';
import { absoluteUrl } from 'lib/utils';
import Image from 'next/image';
import { useState } from 'react';
import PopUpCartItemAdded from 'components/Commerce/PopUpCartItemAdded';
import LoadingSpinner from 'components/Misc/LoadingSpinner';
import { getDefaultVariation } from 'lib/variations';
import ProductDetails from 'components/Commerce/Steps/ProductDetails';
import HeaderSubPage from 'components/HeaderSubPage';
import LoadingSpinnerWrapper from 'components/Misc/LoadingSpinnerWrapper';

interface VoucherProductProps {
  product: any;
  productVariations: any;
}

export default function VoucherProduct({
  product,
  productVariations,
  ...props
}: VoucherProductProps) {
  const [selectedVariations, setSelectedVariations] = useState([
    getDefaultVariation(product, productVariations),
  ]);
  const [showPopUpCartItemAdded, setShowPopUpCartItemAdded] = useState(false);
  const [isValidProductSelection, setIsValidProductSelection] = useState(
    !selectedVariations[0]?.field_has_flexible_price
  );
  const [loading, setLoading] = useState(false);

  return (
    <article {...props} className="bg-tertiary">
      <PopUpCartItemAdded
        show={showPopUpCartItemAdded}
        setShow={setShowPopUpCartItemAdded}
      />
      {loading && (
        <LoadingSpinnerWrapper>
          <LoadingSpinner size={10} />
        </LoadingSpinnerWrapper>
      )}
      <div data-product-type="VoucherProduct" className="relative">
        <div className="mx-auto w-full">
          <div className="relative sm:overflow-hidden">
            <HeaderSubPage
              title={product?.title}
              imageUrl={product?.field_image?.field_media_image?.uri?.url}
              videoUrl={null}
            />
            <Steps>
              <>
                <div>
                  <ProductDetails product={product} />
                  {productVariations?.length > 1 ? (
                    <>
                      <VariationStep
                        productVariations={productVariations}
                        setSelectedVariations={setSelectedVariations}
                        selectedVariation={selectedVariations?.[0]}
                      />
                    </>
                  ) : (
                    <QuantityStep
                      productVariations={productVariations}
                      selectedVariations={selectedVariations}
                      setSelectedVariations={setSelectedVariations}
                      setIsValidProductSelection={setIsValidProductSelection}
                    />
                  )}
                </div>
                {productVariations?.length > 1 ? (
                  <QuantityStep
                    productVariations={productVariations}
                    selectedVariations={selectedVariations}
                    setSelectedVariations={setSelectedVariations}
                    setIsValidProductSelection={setIsValidProductSelection}
                  />
                ) : (
                  []
                )}
              </>
              <AddToCartStep
                selectedVariations={selectedVariations}
                setShowPopUpCartItemAdded={setShowPopUpCartItemAdded}
                isValidProductSelection={isValidProductSelection}
                setLoading={setLoading}
              />
            </Steps>
          </div>
        </div>
      </div>
    </article>
  );
}
